<template>
  <div class="left-product-box" v-show="list.length>0">
    <div class="title">推荐账号</div>
    <div class="list">
      <div class="item" v-for="item in list" :key="item.id">
        <div class="product-name">{{ item.name }}</div>
        <div class="classify">
          <span v-for="(cat,index) in item.category" :key="index">
            {{ cat.value }}{{ index === item.category.length - 1 ? '' : '|' }}
          </span>
        </div>
        <div class="price"><span>¥</span>{{ item.price }}</div>
        <div class="bind">
          <div class="indemnity">找回包赔</div>
          <div class="mailbox" v-if="item.real_email == 1">邮箱实名</div>
          <div class="tap" v-if="item.bind_tap == 1">绑定TAP</div>
        </div>
        <div class="heat-box">
          <div class="heat">
            <img src="@/assets/image/Home/topGamesIco.png" alt="">{{ item.click }}
          </div>
          <router-link target="_blank" :to="{name:'GameDetails',query:{game_id:game_id,id:item.id}}"
              class="info-button">详情 <i class="el-icon-caret-right"></i></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {apiMerchantProduct} from "../request/API";

export default {
  name: "leftProduct",
  data() {
    return {
      list: [],
    }
  },
  props: {
    game_id: {
      type: [String, Number],
      default() {
        return ''
      }
    },
    product_id: {
      type: [String, Number],
      default() {
        return ''
      }
    },
  },
  mounted() {
    if (this.game_id == 26)
      this.handleGetData()
  },
  methods: {
    handleGetData() {
      apiMerchantProduct({
        game_id: this.game_id,
        id: this.product_id,
        merchant_name: 'bz'
      }).then(res => {
        this.list = res.data
      })
    },
  }
}
</script>

<style scoped lang="less">
.left-product-box {
  position: fixed;
  z-index: 999;
  bottom: calc(50% - 401.5px);
  left: calc(100% - 1200px - 238px - ((100% - 1200px) / 2));
  width: 226px;
  min-height: 743px;
  background: #fff3dc;
  border-radius: 10px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);

  .title {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: bold;
    text-align: center;
    color: #6f2800;
    margin: 10px 0;
  }

  .list {
    padding: 0 12px;

    .item {
      width: 188px;
      min-height: 217px;
      background: #ffffff;
      border: 1px solid #a57540;
      border-radius: 5px;
      margin-bottom: 5px;
      padding: 7px 7px 0 7px;

      .product-name {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: bold;
        text-align: LEFT;
        color: #46525d;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
      }

      .classify {
        margin-top: 2px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: #626262;
      }

      .price {
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: bold;
        text-align: left;
        color: #ff8b08;

        span {
          font-size: 16px;
          margin-right: 2px;
        }
      }

      .bind {
        display: flex;
        margin-top: 10px;
        padding-bottom: 6px;
        border-bottom: 1px solid #e4d5cc;
        margin-bottom: 6px;

        .indemnity, .tap, .mailbox {
          width: 60px;
          height: 20px;
          line-height: 20px;
          border: 0.5px solid #e4c594;
          border-radius: 4px;
          text-align: center;
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: bold;
          color: #e2c28e;
          margin-right: 4px;
        }

        .mailbox {
          border-color: #9ABBE8;
          color: #9ABBE8;
        }

        .tap {
          border-color: #FFBF71;
          color: #FFB153;
        }
      }

      .heat-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;

        .heat {
          img {
            width: 13px;
            margin-right: 3px;
          }

          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #5b5b5b;
        }

        .info-button {
          cursor: pointer;
          user-select: none;
          width: 56px;
          height: 24px;
          line-height: 24px;
          background: #fcad13;
          border-radius: 16px;
          text-align: center;
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: bold;
          color: #ffffff;

          .el-icon-caret-right {
            margin-left: -5px;
            transform: scale(.9);
          }
        }
      }
    }
  }
}
</style>
